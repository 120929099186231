(function () {
	'use strict';

	angular
		.module('app')
		.directive('bookingSource', [
			'manageEventSources',
			'manageConfig',
			'utilities',
			bookingSource,
		]);

	function bookingSource(manageEventSources, manageConfig, utilities) {
		return {
			restrict: 'AE',
			scope: {
				settings: '=',
			},
			controller: function ($scope) {
				console.log(
					'selectedSource directive',
					$scope.settings.selectedSource
				);
				console.log('source directive', $scope.settings.source);
				console.log(
					'source template directive',
					$scope.settings.sourceTemplate
				);
				$scope.props = {
					source: $scope.settings.source,
					selectedSource: $scope.settings.selectedSource,
					sourceTemplate: $scope.settings.sourceTemplate,
					viewedSources: $scope.settings.viewedSources,
					getSchedules: manageEventSources.getSchedules,
					updateSource: manageConfig.updateSource,
					deleteSourceProperty: manageConfig.deleteObject,
					help: utilities.help,
				};
			},
			template:
				'<svelte-component component="bookingSource" props="props"></svelte-component>',
		};
	}
})();
